<template>
    <div class="container-fluid footer-bgc" >
        <div class="container" >
            <div class=" row footer-botm" >
               <div class="col-lg-4 footer-l" >
                <img class="footer-logo" src="../../assets/logo-btm.png" alt="" srcset="">
                <p class="footer-name" >五行兔科技</p>
               </div>
                <div class="col-lg-3 footer-r">
                    <ul class="footer-box" >
                        <li>联系我们</li>
                        <li>咨询电话：0571-87326277</li>
                        <li>企业邮箱：</li>
                        <li>办公时间：09：00  - 18：00</li>
                        <li>办公地址：浙江省杭州市滨江区长河街道华业发展中心2702</li>
                    </ul>
                </div>
                <div class="col-lg-2 footer-r">
                    <ul class="footer-box"  >
                        <li>资讯</li>

                        <li>公司动态</li>
                        <li>专题活动</li>
                        <li>行业资讯</li>
                    </ul>
                </div>
                <div class="col-lg-2 footer-r">
                    <ul class="footer-box"  >
                        <li>关于我们</li>
                        <li>公司介绍</li>
                        <li>发展历程</li>
                        <li>企业荣誉</li>
                    </ul>
                </div>
                <div class="col-lg-1 footer-r footer-c">
                    
                    <img  @click="showImagePreview(img)" class="footer-img" :src="img" alt="" srcset="">
                </div>
       
            </div>
        </div>
               <!-- 底部 -->

            <a class="footer-version" href="https://beian.miit.gov.cn/#/Integrated/index" >
                浙ICP备2023028953号-2版权所属五行兔科技有限公司
            </a>
    </div>
</template>
<script setup >
 import { ref } from 'vue'
let img =  ref(require('@/assets/wx.jpg'))

// 图片点击放大
function showImagePreview(imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div');
        previewContainer.style.position = 'fixed';
        previewContainer.style.top = 0;
        previewContainer.style.bottom = 0;
        previewContainer.style.left = 0;
        previewContainer.style.right = 0;
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContainer.style.display = 'flex';
        previewContainer.style.justifyContent = 'center';
        previewContainer.style.alignItems = 'center';
        document.body.appendChild(previewContainer);
        // 在弹出层中添加图片
        const previewImage = document.createElement('img');
        previewImage.src = imageUrl;
        previewImage.style.maxWidth = '90%';
        previewImage.style.maxHeight = '90%';
        previewContainer.appendChild(previewImage);
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer);
        });
      };
    }

</script>
<style lang="scss">
.footer-bgc{
 background-color: #505D8E;


.footer-botm{
    height: 16rem;
    padding-top: 1rem;
    .footer-l{
        display: flex;
        align-items: center;
    .footer-logo{
        width:  4rem;
        height: 4rem;
    }
    .footer-name{
        font-size: 2.5rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: white;
        margin-bottom: 0;
        margin-left: 5%;
    }
    }

    .footer-r{
        // display: flex;
        // align-items: center;
 .footer-box li{
        color: white;
        line-height: 1.8rem;
        list-style: none;
        }
        .footer-box li:first-child{
            font-size: 1.5rem;
            line-height: 3.5rem;
        } 
        .footer-img{
            width: 100%;
            height: 35%;
            display: block;
        }
    }
    .footer-c{
        display: flex;
        align-items: center;
    }
}

.footer-version{
    // height: 2.5rem;
	display: block;
    font-size: 0.8rem;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #AFAFAF;
    line-height: 1.5rem;
    border-top: 1px solid #757D95;
    text-align: center;
}

}


@media only screen and (max-width:992px) {
            .footer-bgc{
                height: 100%;
                .footer-botm{
                    height: 100%;
                }
                .footer-l{
                    justify-content: center;
                    // padding-top: 1rem;
                }
            }
            .footer-version{
                text-align: left !important;
                letter-spacing: 0.2rem;
            }
            .footer-img{
                height: 100% !important;
            }
		}
</style>