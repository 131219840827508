import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/newsView',
    name: 'newsView',
    component: () => import('@/views/newsView')
  },
  {
    path: '/caseView',
    name: 'caseView',
    component: () => import('@/views/caseView.vue')
  },
  {
    path: '/aboutView',
    name: 'aboutView',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/contactView',
    name: 'contactView',
    component: () => import('@/views/contactView.vue')
  },
  { //登录
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  { //注册
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue')
  },
  { //注册
    path: '/release',
    name: 'release',
    component: () => import('@/views/release.vue')
  },
  { //详情
    path: '/details',
    name: 'details',
    component: () => import('@/views/details.vue')
  },
  { //发布内容
    path: '/releaseCent',
    name: 'releaseCent',
    component: () => import('@/views/releaseCent.vue')
  },
  { //分享
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/invite.vue')
  },
  { //邀请下载注册
    path: '/sharingPage',
    name: 'sharingPage',
    component: () => import('@/views/sharingPage.vue')
  },
  { //隐私协议
    path: '/ysPage',
    name: 'ysPage',
    component: () => import('@/views/ysPage.vue')
  },
  { //五行兔隐私协议
    path: '/wxtZc',
    name: 'wxtZc',
    component: () => import('@/views/wxtZc.vue')
  },
  { //服务协议
    path: '/fwPage',
    name: 'fwPage',
    component: () => import('@/views/fwPage.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
