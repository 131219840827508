<template>
        <div>
          <!-- <div class='come' v-show='token!=null' >
            {{ dealTel(phone)}}，欢迎您的到来
          </div>
            <div class='goTo' @click='goTo' v-show='phone!=null' >退出登录</div> -->
        </div>
</template>
  
<script setup>


import {ref,reactive,onMounted, onBeforeUnmount ,watchEffect } from 'vue'
import {ElMessage } from 'element-plus'
import router from "@/router";

let token =  ref(localStorage.getItem("token"))
let phone =  ref(localStorage.getItem("phone")) 
function dealTel(val){
    var reg = /^(\d{3})\d{4}(\d{4})$/
    return val&&val.replace(reg, "$1****$2")
}
 
 function goTo () {
    ElMessage.success('退出成功');
    localStorage.removeItem("token")
     localStorage.removeItem("phone")
    setTimeout(() => {
        router.push('/login')
    }, 1000);
 } 


</script>
  
  
<style lang="scss">


.come{
   color: green;
   font-size:0.9rem;
}
.goTo{
    color: red;
   font-size:0.9rem; 
}
</style>
  