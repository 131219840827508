<template>
	<div class="container-fluid ">
		
		<div class="float-ceiling">
			<svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="float-telephone bi bi-telephone" viewBox="0 0 16 16">
			 <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
			</svg>
			<span  @click="btn" class="contact-us" >联系我们</span>
		</div>
		<!--导航栏引入 -->
		<div class="container heade">
			<nav class="container navbar navbar-expand-lg  animate__animated scrollArea ">
				<div class="col spc-bewn">
					<LogonIn/>
					<div class="navbar-logo">
						<img class="navbar-img"
							src="../assets/logo.png"
							alt="" srcset="">
						<a class="navbar-brand navbar-tit" href="#">五行兔科技</a>
					</div>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
						aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
				</div>
				<div class="col rght collapse navbar-collapse" id="navbarNavAltMarkup">
					<ul class="navbar-nav container-fluid">
		
						<li class="col nav-link text li-box">
							<a href="#/" class="a-none a-link">
								首页
							</a>
						</li>
						<!-- <li class="col nav-link text li-box">
							<a href="#/releaseCent" class="a-none">
								时尚精品
							</a>
						</li> -->
						<!-- <li class="col nav-link text li-box">
							<a href="#/newsView" class="a-none">
								新闻
							</a>
						</li> -->
						<li class="col nav-link text li-box">
							<a href="#/caseView" class="a-none">
								案例
							</a>
						</li>
						<li class="col nav-link text li-box">
							<a href="#/aboutView" class="a-none">
								关于我们
							</a>
						</li>
						<!-- <li class="col nav-link text li-box">
							<a href="#/appView" class="a-none">
								APP
							</a>
						</li> -->
						<li class="col nav-link text li-box">
							<a href="#/contactView" class="a-none">
								联系我们
							</a>
						</li>
						<!-- <li v-show='token==null'  class="col nav-link text li-box">
							<a href="#/login" class="a-none">
								登录
							</a>
						</li>
						<li v-show='token==null' class="col nav-link text li-box">
							<a href="#/register" class="a-none">
								注册
							</a>
						</li>
						<li class="col nav-link text li-box" @click='releaseClick'>
							<a href="javascript:void(0)" class="a-none">
								信息发布
							</a>
						</li> -->
					</ul>
				
				</div>
			</nav>
		</div>



		<!-- banner图 -->
		<div class="row banne animate__animated  scrollArea">
			<div class="col-12 banne-cent">


				<p class="banne-title">让高价值消费更有温度</p>
				<h3 class="banne-introduce">成为全球领先的奢侈品新零售企业</h3>
				<!-- <h3 class="banne-introduce">一点小介绍文案字段展示点</h3> -->
				<button @click="btn" type="button" class="banne-btn btn btn-primary btn-lg">加入我们</button>
			
			</div>
		</div>
		
		
		
		
	
<!-- 		<div class=" container carousel  animate__animated  scrollArea">
			<div class="col-12 " style="height:100%" >
				<el-carousel :interval="3000" type="card" style="height: 100%;"  >
					<el-carousel-item v-for="(item,index) in allData" :key="item" style="height: 100%;" >
		
						<el-image style="width: 100%; height: 100%" :src="item.image" fit="fill" />
						{{item}}
					</el-carousel-item>
				  </el-carousel>
		   </div>
		</div> -->
	
		
		<!-- 标题介绍1 -->
		<div class="container animate__animated scrollArea">

			<h2 class="tit-details">
				Platform service content
			</h2>
			<h5 class="tit-size">
				平台服务内容
			</h5>
			<div class="row  tit-hgt">
				<div class="tit-bgc1"></div>
				<div class="col-12 flex-cent ">
					<div class="tit-1">01</div>

					<div class="row tit-cent">
						<div class="col-sm-4 tit-box">
							<div class="img-box">
								<img src="../assets/service1.png" class="img-hght">
							</div>
							<div class="tit-botm">
								<h3 class="tit-inform">高奢箱包</h3>
								<p class="tit-introduce">国际知名的奢侈品牌箱包，紧随潮流趋势，各类款式一应俱全。</p>
							</div>
						</div>
						<div class="col-sm-4  tit-box">
							<div class="img-box">
								<img src="../assets/service2.png" class="img-hght">
							</div>

							<div class="tit-botm">
								<h3 class="tit-inform">高端珠宝</h3>
								<p class="tit-introduce">涵括入门级、收藏级珠宝，其文化艺术价值和稀有性，具有较高的投资价值。 </p>
							</div>
						</div>
						<div class="col-sm-4  tit-box">
							<div class="img-box">
								<img src="../assets/service3.png" class="img-hght">
							</div>

							<div class="tit-botm">
								<h3 class="tit-inform">名贵腕表</h3>
								<p class="tit-introduce">涵括平价、豪华、顶级腕表品牌，满足日用、收藏等不同消费需求。 </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 标题介绍2 -->
		<div class="container animate__animated  scrollArea">
			<!-- <h2 class="tit-details" >
			title  title  title  title
		</h2>
		<h5 class="tit-size">
			标题
		</h5> -->
			<div class="row  tit-hgt">
				<div class="tit-bgc2"></div>
				<div class="col-12 flex-cent ">
					<div class="tit-2">02</div>

					<div class="row tit-cent">
						<div class="col-sm-4 tit-box">
							<div class="img-box">
								<img src="../assets/service4.png" class="img-hght">
							</div>
							<div class="tit-botm">
								<h3 class="tit-inform">高档服饰</h3>
								<p class="tit-introduce">个性化、限量款，卓越的品质和品牌的专一性，是身份和价值的标志。</p>
							</div>
						</div>
						<div class="col-sm-4  tit-box">
							<div class="img-box">
								<img src="../assets/service5.png" class="img-hght">
							</div>

							<div class="tit-botm">
								<h3 class="tit-inform">大牌美妆</h3>
								<p class="tit-introduce">球顶级化妆品品牌，保税仓直发，正品保障，链路齐全。</p>
							</div>
						</div>
						<div class="col-sm-4  tit-box">
							<div class="img-box">
								<img src="../assets/service6.png" class="img-hght">
							</div>

							<div class="tit-botm">
								<h3 class="tit-inform">清洁护肤</h3>
								<p class="tit-introduce">满足用户日常消费所需，以显著的价格优势及品质，成为用户首选。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- banner2图 -->
		<div class="row banne-details animate__animated  scrollArea">
			<div class="col-12 banne-cent">
				<p class="banne-title">打造线上线下一体化消费服务平台</p>
				<h3 class="banne-introduce">致力于奢侈品综合供应链建设</h3>
				<!-- <h3 class="banne-introduce">一点小介绍文案字段展示点</h3> -->
				<!-- <button  type="button" class="banne-btn btn btn-primary btn-lg">加入我们</button> -->
			</div>
		</div>


		<!-- Partner合作伙伴 -->
		<div class="container animate__animated  scrollArea">

			<h2 class="partners-details">
				SUPPLY BRAND
			</h2>
			<h5 class="partners-size">
				供应品牌
			</h5>

			<div class="row">
				<div class=" col-sm-12  partners-box " @click="showImagePreview"></div>
			</div>
		</div>

		<!-- footer-nav关于我们  -->
		<Footer class="animate__animated  scrollArea" />

	</div>
</template>

<script setup>
import axios from	'axios'	
import router from "@/router";
import Footer from "./component/footer.vue"
import { onMounted, onBeforeUnmount,ref, reactive } from 'vue'
import { queryList } from './utils/api.js'
import {ElMessage } from 'element-plus'
import  LogonIn from "./component/loginIn.vue";
let imageUrl = require('@/assets/sponsor.png')

let token =  ref(localStorage.getItem("token"))

const allData = ref([])



function handleScroll() {
	window.addEventListener('scroll', scrollArea);
}


onMounted(() => {
	handleScroll();
	scrollArea()
	// queryAll()

});
onBeforeUnmount(() => {
	window.removeEventListener('scroll', () => { }); // 离开当前组件别忘记移除事件监听
});




function releaseClick() {
  if( token.value==null){
    ElMessage({
                message: '请登录！',
                type: 'warning',
            })
       router.push('/login')
  } else{
      router.push('/release')
  }
}

function scrollArea() {
	// 1.获取所有的box
	var boxList = document.querySelectorAll('.scrollArea')
	//2. 定义一个目标值
	// 0.8默认值
	var targetValue = window.innerHeight * 1
	//3.获取每一个box距离浏览器顶部的值
	boxList.forEach(function (box) {
		var boxTop = box.getBoundingClientRect().top
		if (boxTop <= targetValue) {
			box.classList.add('animate__fadeInDown')
		} else {
			// box.classList.remove('animate__bounceInLeft')
		}
	})
}
// 图片点击放大
function showImagePreview() {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div');
        previewContainer.style.position = 'fixed';
        previewContainer.style.top = 0;
        previewContainer.style.bottom = 0;
        previewContainer.style.left = 0;
        previewContainer.style.right = 0;
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContainer.style.display = 'flex';
        previewContainer.style.justifyContent = 'center';
        previewContainer.style.alignItems = 'center';
        document.body.appendChild(previewContainer);
        // 在弹出层中添加图片
        const previewImage = document.createElement('img');
        previewImage.src = imageUrl;
        previewImage.style.maxWidth = '90%';
        previewImage.style.maxHeight = '90%';
        previewContainer.appendChild(previewImage);
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer);
        });
      };
    }
 // 跳转到联系我们
 function btn(){
		router.push('/contactView')
	}
function queryAll(){
	axios.post(`https://www.zjwxt.com/api/publish/release/list`,{})
		.then(res=>{
			allData.value = [...res.data.rows]           
		})
	}
// function carouselFun(e){
// 		// router.push('/details?parme='+ JSON.stringify(e))
// 		router.push({
// 		        path: '/details' , 
// 		        query: {parme: JSON.stringify(e)}
// 		    })
// }



</script>


<style lang="scss">
.float-ceiling{
	width:3.25rem;
	height:8rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content:space-around;
	background: #FFFFFF;
	box-shadow: 0px 2px 3px 0px rgba(77,77,77,0.14);
	border-radius: 2px;
	position: fixed;
	right: 0;
	top: 70%;
	z-index: 5;
	.float-telephone{
	width: 1rem;
	height: 1rem;
	color: #425383;
   }
   .contact-us{
	width:.9rem;
	font-size: 1rem;
	font-family: Adobe Heiti Std;
	font-weight: normal;
	color: #425383;
	line-height: 1.2rem;
   }
}

// 导航栏
.heade {
	.a-none {
		text-decoration: none;
		display: block;
		font-size: 1rem;
		color: #535353;
	}

	.a-link {
		font-size: 1.1rem;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #425383;
	}

	.spc-bewn {
		display: flex;
		justify-content: space-between;

		.navbar-logo {
			display: flex;
			justify-content: space-around;

			.navbar-img {
				width: 3.12rem;
				height: 3.12rem;
			}

			.navbar-tit {
				font-size: 1.87rem;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #425383;
				margin-left: 1rem;
			}

		}

	}
}

// 广告
.banne {
	height: 46.8rem;
	// background-color: sandybrown;	
	background: url('../assets/about-show.png') no-repeat center;
	background-size: 100% 100%;

	.banne-cent {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgba(0,0,0,.4);
		.banne-title {
			font-size: 4.4rem;
			margin-bottom: 2.6rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
		}

		.banne-introduce {
			font-size: 1.5rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
		}

		.banne-btn {			
			font-size: 1.75rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
			margin-top: 4.5rem;
			background-color: #425383;
			border-color:#425383;
		}
	}

}


.carousel{
	margin-top: 10rem;
	height: 40rem;
	.el-carousel__container{
		height: 100%;
	}
}
// 标题样式
.tit-details {
	font-size: 2.625rem;
	font-family: yixinliyuanti;
	font-weight: 400;
	color: #5B5E66;
	text-align: center;
	margin: 5.75rem auto 1.43rem;
}

.tit-size {
	text-align: center;
	font-size: 1.875rem;
	font-family: MiSans;
	font-weight: 600;
	color: #425383;
	margin-bottom: 2.75rem;
}

.tit-hgt {
	height: 32rem;
	// background-color: seagreen;
	position: relative;
	margin-bottom: 5.25rem !important;

	.tit-bgc1 {
		width: 20%;
		height: 100%;
		background: #425383;
		position: absolute;
		z-index: 1;
	}

	.tit-bgc2 {
		width: 20%;
		height: 100%;
		background: #425383;
		position: absolute;
		z-index: 1;
		right: 0;
	}

	.flex-cent {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.tit-cent {
			justify-content: space-evenly;
		}

		.tit-1,
		.tit-2 {
			// height: 2.4rem;
			position: relative;
			z-index: 1;
			font-size: 3rem;
			font-family: MiSans;
			font-weight: 600;
			color: #FFFFFF;
		}

		.tit-1 {
			padding-left: 3%;
		}

		.tit-2 {
			text-align: right;
			padding-right: 3%;
		}
	}

	.tit-box {
		height: 25rem;
		// width: 21.6rem;
		width: 28%;
		background: #FFFFFF;
		position: relative;
		z-index: 2;
		margin-bottom: 1.5rem;
		padding: 0;

		.img-box {
			height: 64%;
			width: 100%;
			overflow: hidden;
		}

		.img-hght {
			width: 100%;
			height: 100%;
			transition: all linear .6s;
		}

		.img-box .img-hght:hover {
			transform: rotate(-5deg) scale(1.2);
		}

		.tit-botm {
			padding: 0 1.6rem;

			.tit-inform {
				font-size: 1.75rem;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #5B5E66;
				margin: 1rem 0;
			}

			.tit-introduce {
				font-size: 1rem;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #999999;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				/* 多行文本的行数  3 - 表示三行文本超出后在第三行后显示（...） */
				-webkit-box-orient: vertical;
				/* 比较适用于webkit浏览器及移动端浏览器（不兼容） */
			}
		}



	}
}

// 介绍样式

.banne-details {
	height: 33.6rem;
	background: url('../assets/banner.png') no-repeat center;
	background-size: 100% 100%;

	.banne-cent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.banne-title {
			font-size: 4.4rem;
			margin-bottom: 2.6rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
		}

		.banne-introduce {
			font-size: 1.5rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
		}

		.banne-btn {
			font-size: 1.78rem;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #FFFFFF;
			margin-top: 4.5rem;
		}
	}

}

// 合作伙伴
.partners-details {
	font-size: 2.625rem;
	font-family: yixinliyuanti;
	font-weight: 400;
	color: #5B5E66;
	text-align: center;
	margin: 5.75rem auto 1.43rem;
}

.partners-size {
	text-align: center;
	font-size: 1.875rem;
	font-family: MiSans;
	font-weight: 600;
	color: #425383;
	margin-bottom: 2.75rem;
}

.partners-box {
	height: 40.1rem;
	background: url('../assets/sponsor.png') no-repeat center;
	background-size: 100% 100%;
	margin-bottom: 3.6rem;
}



@media only screen and (max-width:992px) {

	.banne{
		height: 30rem;
		.banne-title{
			font-size:2.5rem !important;
			text-align: center;
		}
		.banne-introduce{
			height: 1.2rem;
		}
		.banne-btn{
			font-size: 1.5rem !important;
			margin-top: 3rem !important;
		}
	}
	
	.carousel{
		height: 16rem;
		margin-top: 2.5rem;
	}
.tit-details {
		margin: 2rem auto 1.43rem;
}

	.banne-details{
		.banne-title{
			font-size:2.5rem !important;
			text-align: center;
		}
		.banne-introduce{
			height: 1.2rem;
		}
	}

	.tit-hgt {
		height: 83.875rem;

		.tit-bgc1,
		.tit-bgc2 {
			width: 100%;
			background-color: #425383;
		}

		.tit-box {
			width: 21.6rem;
		}
	}
}
</style>
