import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import 'animate.css' 
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import elementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css';




import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';








const app = createApp(App)
app.use(router).use(elementPlus).mount('#app')

app.component('QuillEditor', QuillEditor)